<template>
  <section class="section">
    <div class="container">
      <MosaicUiTabsAllServicesTabs>
        <template #content="{tab}">
          <SplideSlide v-for="item in psychologistsByServices[tab]"
                       :key="`catalogItem-${item.id}`"
                       class="pb-5"
          >
            <ComponentMarketplaceCatalogItem
              :item="item"
              :link="localePath(`/psychologists/view/${item.id}`)"
              class="h-full"
            >
              <template #buttons="{isCheckoutEnabled}">
                <MosaicUiButtonsPrimaryButton
                  :tag="NuxtLink"
                  :to="localePath(`/psychologists/checkout/${item.id}`)"
                  class="btn w-full mb-4"
                  full-rounded
                  v-if="isCheckoutEnabled"
                >
                  {{ t('cabinet-customer.catalog.buttons.checkout') }}
                </MosaicUiButtonsPrimaryButton>
                <MosaicUiButtonsSecondaryButton
                  :tag="NuxtLink"
                  :to="localePath(`/psychologists/view/${item.id}`)"
                  class="w-full mb-4"
                  full-rounded
                >
                  {{ t('cabinet-customer.catalog.buttons.details') }}
                </MosaicUiButtonsSecondaryButton>
              </template>
            </ComponentMarketplaceCatalogItem>
          </SplideSlide>
        </template>
      </MosaicUiTabsAllServicesTabs>
    </div>
  </section>
</template>

<script setup lang="ts">
import {NuxtLink} from "#components";
import {onMounted} from "vue";

const {t} = useI18n();
const localePath = useLocalePath();
const {fetchItems, catalog} = useCatalogRandom();

const psychologistsByServices = ref({
  individual: [],
  kids: [],
  family: [],
})
onMounted(() => {
  Object.keys(psychologistsByServices.value).forEach(key => {
    fetchItems([key]).then((catalog) => psychologistsByServices.value[key] = catalog.items);
  })
})
</script>
