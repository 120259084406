<template>
    <div class="association-item">
        <figure>
            <slot />
        </figure>
        <div class="users-photo-line mini">
            <div class="line">
                <div class="item" v-for="(img, index) in imgs" :key="index">
                    <img :src="img" alt="" loading="lazy" />
                </div>
            </div>
            <span>+{{ count }}</span>
        </div>
    </div>
</template>
<script setup>
const {count, img} = defineProps({
    count: String,
    imgs: Array
})
</script>
