<template>
  <section class="section seo-section">
    <div class="container">
      <div class="seo-spoiler" v-html="seoText" :class="{'is-active': visibleText}"></div>
      <span class="seo-show" v-if="!visibleText" @click="triggerBtn">{{ t('meclee-ui.app.show_seo') }}</span>
      <span class="seo-hide" v-else @click="triggerBtn">{{ t('meclee-ui.app.hide_seo') }}</span>
    </div>
  </section>
</template>
<script setup>
const visibleText = ref(false)
const triggerBtn = () => visibleText.value = !visibleText.value

import { useLocalePath } from "#i18n";
const localePath = useLocalePath();
const { t } = useI18n();
const {$seo} = useNuxtApp();

const seoText = computed(() => {
  return $seo.getSeoText();
})
</script>
