<template>
  <section class="section about-psychologists-section">
    <div class="container has-text-centered">
      <h2 class="title is-spaced">
        {{ t('meclee-ui.landings.main.about-psychologists.title') }}
      </h2>
      <p class="subtitle">{{ t('meclee-ui.landings.main.about-psychologists.subtitle') }}
      </p>

      <Splide class="associations splide" :options="sliderOptions">
        <SplideSlide>
          <AssociationsCard count="10" :imgs="[User1, User9, User3, User7]">
            <img :src="AssociationImg1" loading="lazy" alt="" />
          </AssociationsCard>
        </SplideSlide>
        <SplideSlide>
          <AssociationsCard count="5" :imgs="[User2, User10, User5, User8]">
            <img :src="AssociationImg2" loading="lazy" alt="" />
          </AssociationsCard>
        </SplideSlide>
        <SplideSlide>
          <AssociationsCard count="3" :imgs="[User4, User11, User6, User12]">
            <img :src="AssociationImg3" loading="lazy" alt="" />
          </AssociationsCard>
        </SplideSlide>
      </Splide>

      <Splide class="advantages splide" :options="sliderOptions">
        <SplideSlide v-for="(advantage, index) in psychologistAdvantages" :key="advantage.key">
          <AdvantageCard :advantage="advantage">
            <template #advantage-icon>
              <img src="/meclee-ui/icons/icon_advantages_1.svg" loading="lazy" v-if="index === 0" />
              <img src="/meclee-ui/icons/icon_advantages_2.svg" loading="lazy" v-else-if="index === 1" />
              <img src="/meclee-ui/icons/icon_advantages_3.svg" loading="lazy" v-else-if="index === 2" />
            </template>
          </AdvantageCard>
        </SplideSlide>
      </Splide>
    </div>
  </section>
</template>
<script setup>
import "@splidejs/vue-splide/css";
import AssociationImg1 from "../../assets/images/image_association_1.png"
import AssociationImg2 from "../../assets/images/image_association_2.png"
import AssociationImg3 from "../../assets/images/image_association_3.png"
import User1 from "../../assets/images/users/01.jpg"
import User2 from "../../assets/images/users/02.jpg"
import User3 from "../../assets/images/users/03.jpg"
import User4 from "../../assets/images/users/04.jpg"
import User5 from "../../assets/images/users/05.jpg"
import User6 from "../../assets/images/users/06.jpg"
import User7 from "../../assets/images/users/07.jpg"
import User8 from "../../assets/images/users/08.jpg"
import User9 from "../../assets/images/users/09.jpg"
import User10 from "../../assets/images/users/10.jpg"
import User11 from "../../assets/images/users/11.jpg"
import User12 from "../../assets/images/users/12.jpg"
import AssociationsCard from "./AssociationsCard.vue";
import AdvantageCard from "./AdvantageCard.vue";

import { useLocalePath } from "#i18n";
const localePath = useLocalePath();
const { t } = useI18n();

const { psychologistAdvantages } = defineProps({
  psychologistAdvantages: Array,
});

const sliderOptions = {
  type: 'slide',
  perPage: 3,
  perMove: 1,
  arrows: false,
  pagination: false,
  gap: '30px',
  breakpoints: {
    1000: {
      perPage: 3,
      pagination: false,
      arrows: false,
      gap: '20px',
      padding: {
        left: '0',
        right: '35px',
      },
    },
    768: {
      perPage: 2,
      pagination: false,
      arrows: false,
      gap: '20px',
      padding: {
        left: '0',
        right: '35px',
      },
    },
    450: {
      perPage: 1,
      pagination: false,
      arrows: false,
      gap: '20px',
      padding: {
        left: '0',
        right: '35px',
      },
    },
  }
};
</script>
