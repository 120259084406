<template>
  <WidgetMecleeUiLandingsHomepageLanding
    :welcomeContent="welcomeContent"
    :matchingLink="localePath('matching')"
    :whyChooseUsContent="whyChooseUsContent"
    :psychologistAdvantages="advantages"
    :faqPsychologistContent="faqList"
    :tabs="tabs"
    :reviews="reviews"
  />
</template>

<script setup>
definePageMeta({
  layout: 'public',
});

const { $seo } = useNuxtApp();
const { t } = useI18n();
const config = useRuntimeConfig();
const route = useRoute();
const localePath = useLocalePath();
useSeoMeta({
  title: $seo.getTitle('seo./.title'),
  description: $seo.getDescription(),
})

const schemaOrg = ref([
  defineBreadcrumb({
    itemListElement: [
      defineListItem({
        item: {
          '@id': config.public.url + localePath(`/`),
          name: $seo.getTitle('seo./.title')
        }
      })
    ]
  }),
]);
useSchemaOrg(schemaOrg.value);

const { data: faqList, error } = await useAsyncData(
  'faqList',
  async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const {faqList, fetchFaqList} = useContentFaq();
        await fetchFaqList('main');

        faqList.value.forEach((item) => {
          schemaOrg.value.push(
            defineQuestion({
              name: item.question,
              acceptedAnswer: {
                text: item.answer.replace(/(<([^>]+)>)/gi, ""),
              }
            })
          );
        })
        resolve(faqList.value);
      } catch (error) {
        reject(error);
      }
    });
  }
)

const tabs = [
  {
    key: "ForMe",
    title: 'meclee-ui.landings.main.for-who.tabs.me.title'
  },
  {
    key: "ForKids",
    title: 'meclee-ui.landings.main.for-who.tabs.kids.title'
  },
  {
    key: "ForPair",
    title: 'meclee-ui.landings.main.for-who.tabs.pair.title'
  }
]

const welcomeContent = {
  key: "welcome",
  title: ['meclee-ui.landings.main.welcome.main.title.0', 'meclee-ui.landings.main.welcome.main.title.1'],
  description: 'meclee-ui.landings.main.welcome.main.subtitle',
  btnText: 'meclee-ui.landings.main.welcome.main.button',
}

const reviews = ['MariaF', 'BohdanS', 'IrynaM', 'TarasL', 'MaksymR', 'AnnaU', 'KaterynaM', 'AnastasiiaN'].map((key) => {
  return {
    key: key,
    name: `meclee-ui.landings.main.reviews.list.${key}.name`,
    specialty: `meclee-ui.landings.main.reviews.list.${key}.specialty`,
    text: `meclee-ui.landings.main.reviews.list.${key}.text`,
  }
});

const advantages = [
  {
    key: 1,
    title: 'meclee-ui.landings.main.about-psychologists.advantages.0.title',
    descriptionItems: [
      'meclee-ui.landings.main.about-psychologists.advantages.0.list.0',
      'meclee-ui.landings.main.about-psychologists.advantages.0.list.1',
    ],
  },
  {
    key: 2,
    title: 'meclee-ui.landings.main.about-psychologists.advantages.1.title',
    descriptionItems: [
      'meclee-ui.landings.main.about-psychologists.advantages.1.list.0',
      'meclee-ui.landings.main.about-psychologists.advantages.1.list.1',
    ],
  },
  {
    key: 3,
    title: 'meclee-ui.landings.main.about-psychologists.advantages.2.title',
    descriptionItems: [
      'meclee-ui.landings.main.about-psychologists.advantages.2.list.0',
      'meclee-ui.landings.main.about-psychologists.advantages.2.list.1',
    ],
  },
]

const whyChooseUsContent = [
  {
    key: 1,
    contentTitle: 'meclee-ui.landings.main.why-choose-us.content.0.title',
    contentText: 'meclee-ui.landings.main.why-choose-us.content.0.description',
    img: "why_choose_us_1",
  },
  {
    key: 2,
    contentTitle: 'meclee-ui.landings.main.why-choose-us.content.1.title',
    contentText: 'meclee-ui.landings.main.why-choose-us.content.1.description',
    img: "why_choose_us_2",
  },
  {
    key: 3,
    contentTitle: 'meclee-ui.landings.main.why-choose-us.content.2.title',
    contentText: {
      text: 'meclee-ui.landings.main.why-choose-us.content.2.description',
      list: [
        'meclee-ui.landings.main.why-choose-us.content.2.list.0',
        'meclee-ui.landings.main.why-choose-us.content.2.list.1',
      ],
    },
    img: "illustration_main_2",
  },
  {
    key: 4,
    contentTitle: 'meclee-ui.landings.main.why-choose-us.content.3.title',
    contentText: 'meclee-ui.landings.main.why-choose-us.content.3.description',
    img: "illustration_main_3",
  },
  {
    key: 5,
    contentTitle: 'meclee-ui.landings.main.why-choose-us.content.4.title',
    contentText: {
      text: null,
      list: [
        'meclee-ui.landings.main.why-choose-us.content.4.list.0',
        'meclee-ui.landings.main.why-choose-us.content.4.list.1',
      ],
    },
    img: "illustration_main_4",
  },
];
</script>

